

































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Form, Notification} from 'element-ui'

import {Seller, Location} from '@/models/index'
import DirectUpload from '@/components/DirectUpload/index.vue'

import {
  apiClientOptions,
  offsetOptions,
  partialModeOffsetOptions,
  paymentModeOptions,
  payVatOptions, vatPaybackOptions, invoicePerOptions
} from './selectOptionsSeller'

@Component({
  name: 'DialogSeller',
  components: {
    DirectUpload
  }
})
export default class extends Vue {
  @Prop({ required: true }) private visible!: boolean
  @Prop({ required: true }) private seller!: Seller | null

  private dialogStatus = ''
  private submitLoading = false
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private apiClientOptions = apiClientOptions
  private paymentModeOptions = paymentModeOptions
  private invoicePerOptions = invoicePerOptions
  private offsetOptions = offsetOptions
  private partialModeOffsetOptions = partialModeOffsetOptions
  private payVatOptions = payVatOptions
  private vatPaybackOptions = vatPaybackOptions

  private tempData = new Seller({ location: new Location() })

  private rules = {
    vat: [{ required: false, message: 'tva is required', trigger: 'change' }],
    siret: [{ required: false, message: 'siret is required', trigger: 'change' }]
  }

  private resetTempData() {
    this.tempData = new Seller({ location: new Location() })
  }

  @Watch('seller', { immediate: true })
  setDataDialog(): void {
    if (this.seller) this.handleUpdate()
    else this.handleCreate()
  }

  get clientOrderByName(): any {
    return this.apiClientOptions.sort((a: any, b: any) => a.name.localeCompare(b.name))
  }

  private ruleTVA(_rule: never, value: any, callback: any) {
    if (/^[A-Za-z]{2,4}(?=.{2,12}$)[\-_ 0-9]*(?:[a-zA-Z][-_ 0-9]*){0,2}$/.test(value)) callback()
    else callback(new Error('Incorrect format'))
  }

  private ruleSiret(_rule: never, value: any, callback: any) {
    if (/^\d{14}$/.test(value)) callback()
    else callback(new Error('Incorrect format'))
  }


  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private handleUpdate() {
    this.tempData = this.seller!.dup()
    if (!this.tempData.location) this.tempData.location = new Location()
    this.dialogStatus = 'update'
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async (valid: any) => {
      if (valid) {
        this.submitLoading = true

        try {
          const data = this.tempData

          await data.save({with: 'location'})

          if (this.dialogStatus === 'create') {
            this.$notify({
              title: 'Seller created',
              message: 'Seller successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              title: 'Seller updated',
              message: 'Seller successfully updated',
              type: 'success',
              duration: 2000
            })
          }
          this.submitLoading = false
          this.$emit('close')
        } catch (e) {
          for (const error of (e as any).response.data.errors) {
            if (error) {
              Notification({
                title: error.title,
                message: error.detail,
                type: 'error',
                duration: 5000
              })
            }
          }
          throw e
        }
      }
    })
  }

  private uploadCallback(file: any) {
    this.tempData.logo = file.blob.signed_id
  }
}
